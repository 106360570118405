// import CardBody from "../components/CardBody";
import CardComingSoon from "../components/CardComingSoon";
// import CardHeader from "../components/CardHeader";

const Card = () => {
  return (
    <>
      <CardComingSoon />
      {/* <CardHeader/> */}
      {/* <CardBody /> */}
    </>
  );
};

export default Card;
